// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

.stream-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    border-radius: var(--border-radius);

    &:hover, &:focus {
        background-color: var(--overlay-color);
    }

    &:hover, &:focus, &:global(.selected) {
        .icon {
            opacity: 1;
        }
    }

    .info-container {
        flex: none;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .thumbnail {
            display: block;
            height: 5rem;
            width: 8rem;
            object-fit: cover;
            object-position: center;
            opacity: 0.9;
        }

        .placeholder-icon {
            display: block;
            height: 5rem;
            width: 8rem;
            padding: 1rem;
            color: var(--primary-foreground-color);
            background-color: var(--overlay-color);
            opacity: 0.25;
        }

        .addon-name {
            width: 7rem;
            max-height: 3.6em;
            font-size: 1.1rem;
            text-align: left;
            color: var(--primary-foreground-color);
        }

        .progress-bar-container {
            flex: 0 0 100%;
            position: relative;
            border-radius: var(--border-radius);

            .progress-bar {
                z-index: 1;
                position: relative;
                height: 0.4rem;
                background-color: var(--primary-accent-color);
            }

            .progress-bar-background {
                z-index: 0;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: var(--primary-foreground-color);
                opacity: 0.2;
            }
        }
    }

    .description-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: pre;
        overflow-y: visible;
        text-overflow: ellipsis;
        color: var(--primary-foreground-color);
    }
    
    .icon {
        flex: none;
        width: 3rem;
        height: 3rem;
        padding: 0.7rem;
        border-radius: 50%;
        opacity: 0;
        color: var(--primary-foreground-color);
        background-color: var(--secondary-accent-color);
    }
}

@media only screen and (max-width: @small) {
    .stream-container {
        .description-container {
            font-size: 0.9rem;
        }
    }
}

@media only screen and (max-width: @minimum) {
    .stream-container {
        .info-container {
            .addon-name {
                font-weight: 500;
            }
        }
    }
}
